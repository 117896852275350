import { booleanAttribute, ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslocoDirective } from '@jsverse/transloco';
import { Typography, TypographyDirective } from '@shared/directives/typography.directive';
import { i18nAttribute } from '@shared/helpers';
import { BadgeComponent } from '@shared/ui/badge/badge.component';
import { IconComponent, IconName } from '../../icon/icon.component';

export type Tab = {
  id: string | number;
  title: string;
  path?: string;
  icon?: IconName;
  visible?: boolean;
  count?: {
    value: number;
    style?: 'hidden' | 'positive';
  };
};

@Component({
  selector: 'coach-tab',
  host: {
    '[class.vertical]': 'vertical()',
    '[class.square]': 'square()',
    '[class.chip]': 'chip()',
    '[id]': '"tab-" + tab().id',
  },
  imports: [RouterLink, RouterLinkActive, TranslocoDirective, TypographyDirective, IconComponent, BadgeComponent],
  templateUrl: './tab.component.html',
  styleUrl: './tab.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabComponent {
  tab = input.required<Tab>();
  active = input(false, { transform: booleanAttribute });
  vertical = input(false, { transform: booleanAttribute });
  square = input(false, { transform: booleanAttribute });
  chip = input(false, { transform: booleanAttribute });

  title = computed(() => i18nAttribute(this.tab().title));

  typography = computed<Typography>(() => {
    const type =
      this.square() ? 'label'
      : this.chip() ? 'caption'
      : 'subtitle';
    const variant = type === 'subtitle' ? '2' : '1';

    return {
      type,
      variant,
    };
  });

  clicked = output<Tab>();
}
