import { ChangeDetectionStrategy, Component, computed, input, numberAttribute } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { i18nAttribute } from '@shared/helpers';
import { ActionType } from '@shared/types/generic.types';
import { IconComponent, IconName } from '../icon/icon.component';

type BadgeType = ActionType | 'default' | 'secondary';

@Component({
  selector: 'coach-badge',
  imports: [IconComponent, TranslocoDirective],
  host: { '[class]': '[type(), "label"]', '[class.active]': 'count() > 0', '[class.with-text]': 'text()?.length > 0' },
  templateUrl: './badge.component.html',
  styleUrl: './badge.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent {
  count = input(undefined, { transform: numberAttribute });
  text = input(undefined, { transform: i18nAttribute });
  type = input<BadgeType>('default');
  icon = input<IconName>();
  countLabel = computed(() => (this.count()! > 99 ? '99+' : this.count()));
}
