@if (title(); as title) {
  <span class="title" typography="overline">{{ title }}</span>
}
@for (tab of tabs(); track tab.id) {
  @if (tab.visible === undefined || tab.visible) {
    <coach-tab
      [tab]="tab"
      [active]="tab.id === activeTabId()"
      [vertical]="vertical()"
      [square]="square()"
      [chip]="chips()"
      (clicked)="tabClicked($event)" />
  }
}
