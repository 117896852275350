@if (tab(); as tab) {
  <button
    *transloco="let t"
    class="tab"
    [typography]="typography().type"
    [variant]="typography().variant"
    [class.open]="active() && !tab.path"
    [routerLink]="tab.path"
    routerLinkActive="active"
    ariaCurrentWhenActive="page"
    (click)="clicked.emit(tab)">
    @if (tab.icon !== undefined) {
      <coach-icon [icon]="tab.icon!" />
    }
    <span class="title" [class.with-icon]="tab.icon">
      {{ title().i18n ? t(title().value, { count: tab.count?.value }) : title().value }}
    </span>
    @if (tab.count; as count) {
      @if (count !== undefined && count?.style !== 'hidden') {
        @if (!count.style || (count?.style === 'positive' && count.value > 0)) {
          <coach-badge class="badge" [class.notification]="count.style === 'positive'" [count]="count?.value" />
        }
      }
    }
  </button>
}
